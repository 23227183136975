<template>
    <div>
        <DevOnly>
            <LazyUiAppBannerAuthRoles />
        </DevOnly>
        <AppHeader2 />
        <main>
            <div class="pb-8 md:pb-16">
                <slot />
            </div>
        </main>
        <LazyAppFooter />
    </div>
</template>